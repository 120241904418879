import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getVideoId from 'util/video';
import VideoSlider from '../more-about/video-slider';
/* import { AboutBanner } from 'components/pages/schools-results-v2/personality-overview/components/about-banner/about-banner'; */
import { t } from 'components/translation/Translation';
import HtmlParser from 'html-react-parser';
import './school-about.scss';

export const SchoolAbout = ({ school }) => {
  const { videos, mission, vision } = school;
  const [videoSrc, setVideoSrc] = useState('');
  const [videoID, setvideoID] = useState('');

  const displayVideo = videos ? videos.filter((video) => video != null)[0] : undefined;
  const _videos = videos ? videos.filter((video) => video != null) : undefined;

  const getDisplayVideo = () => {
    if (displayVideo) {
      const metadata = getVideoId(displayVideo.embed_code);
      if (metadata) {
        let _video;
        const _videoID = metadata.id;

        if (/youtube/.test(displayVideo.embed_code)) {
          _video = `${displayVideo.embed_code}?rel=0`;
        } else if (/vimeo/.test(displayVideo.embed_code)) {
          _video = `https://player.vimeo.com/video/${_videoID}`;
        } else {
          _video = displayVideo.embed_code;
        }
        setVideoSrc(_video);
        setvideoID(_videoID);
      }
    }
  };

  const getPlayer = () => {
    if (videoSrc && videoSrc.includes('youtube')) {
      return (
        <iframe
          width="100%"
          height="350"
          src={videoSrc}
          srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${videoID}?autoplay=1><img src=https://img.youtube.com/vi/${videoID}/sddefault.jpg alt='School Video'><span>▶</span></a>`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={videoSrc}
        />
      );
    }
    return (
      <iframe
        width="100%"
        height="350"
        src={videoSrc}
        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${videoSrc}><img src="https://vumbnail.com/${videoID}.jpg" alt='School Video'><span>▶</span></a>`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={videoSrc}
      />
    );
  };

  useEffect(() => {
    getDisplayVideo();
  }, []);

  if (!mission && !vision && videos.length === 0) return null;

  return (
    <div className="school-about spacing-mb-16">
      <h3 className="heading-4">About the School</h3>
      {mission && mission !== '<p></p>' && mission !== '<p><br></p>' && (
        <div className="school-about__section">
          <h4 className="school-about__subtitle">{t('schoolProfile:ourMission')}</h4>
          {HtmlParser(mission.replace(/\r?\n/g, '<br />'))}
        </div>
      )}
      {vision && vision !== '<p></p>' && vision !== '<p><br></p>' && (
        <div className="school-about__section">
          <h4 className="school-about__subtitle">{t('schoolProfile:ourVision')}</h4>
          {HtmlParser(vision.replace(/\r?\n/g, '<br />'))}
        </div>
      )}
      {videos.length === 1 && (
        <div className="school-about__video spacing-mb-32">
          <div className="">{getPlayer()}</div>
        </div>
      )}
      {videos.length > 1 && (
        <div className="school-about__video spacing-mb-32">
          <VideoSlider videos={_videos} />
        </div>
      )}

      {/* <AboutBanner
        className="spacing-my-32"
        contactAction={contactAction}
        title="We Are The Best School!"
        subtitle="This is a persuasive sentence explaining why we are the best:"
        description="We are accepting applications from this date to this date. Don’t miss out on unlocking your child’s potential at Academy of Media Arts!"
        buttonText="Request Info Now!"
        arrowTextLeft="Start Here"
        arrowTextRight="You Got This!"
      /> */}
    </div>
  );
};

SchoolAbout.propTypes = {
  school: PropTypes.object.isRequired,
};
