import moment from 'moment';
import { hasPremium, planName } from 'util/schoolUtils';

export const isRecruiterPro = ({
  plan_id: planId,
  subscription_months: subscriptionMonths,
  subscription_end: subscriptionEnd,
}) => {
  return (
    planName({
      plan_id: planId,
      subscription_end: subscriptionEnd,
      subscription_months: subscriptionMonths,
      hasPremiumAccess: !!(
        planId &&
        planId !== '' &&
        planId !== null &&
        planId.toLowerCase() !== 'scholabasics' &&
        moment(subscriptionEnd).isAfter()
      ),
    }) === 'scholarecruiterpro'
  );
};
