import { createSelector, createSlice } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { CONTACT_STAGES } from './types';
import { isEmail, isPhone, isZipCode } from 'util/validate';
import { t } from 'components/translation/Translation';
import { isZipcodeIncluded } from 'components/pages/school-profile/contact-school/utils';

const initialState = {
  isContactOpen: false,
  schoolId: null,
  stage: CONTACT_STAGES.contact,
  contactType: '',
  students: [{ id: uniqueId(), name: '', grade: '' }],
  mainMessage: '',
  language: '',
  additionalMatch: null,
  statusLead: '',
  contact: {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  },
  isContactSubmitting: false,
  errorsActive: {
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    zipcode: false,
    students: false,
  },
};

const contactSchoolSlice = createSlice({
  name: 'contactSchool',
  initialState,
  reducers: {
    openContact: (state) => {
      state.isContactOpen = true;
    },
    setSchoolId: (state, { payload }) => {
      state.schoolId = payload;
    },
    closeContact: (state) => {
      state.isContactOpen = false;
    },
    setStage: (state, { payload }) => {
      state.stage = payload;
    },
    setZip: (state) => {
      state.contact.zipcode = '';
    },
    onContact: (state, { payload }) => {
      state.schoolId = payload.schoolId;
    },
    addStudent: (state) => {
      state.students.push({ id: uniqueId(), name: '', grade: '' });
    },
    removeStudent: (state, action) => {
      if (!action.payload) {
        state.students.pop();
      } else {
        state.students = state.students.filter((item) => item.id !== action.payload);
      }
    },
    handleStudent: (state, { payload }) => {
      const student = state.students.find((item) => item.id === payload.id);
      if (student) {
        student[payload.name] = payload.value;
      }
    },
    handleContact: (state, { payload }) => {
      state.contact[payload.name] = payload.value;
    },
    setSubmitting: (state, { payload = true }) => {
      state.isContactSubmitting = payload;
    },
    activateErrors: (state, { payload }) => {
      if (payload && payload.field) {
        // Activate errors only for a specific field
        state.errorsActive = {
          ...state.errorsActive,
          [payload.field]: true,
        };
      } else {
        Object.keys(state.errorsActive).forEach((key) => {
          state.errorsActive[key] = true;
        });
      }
    },
    handleInput: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    setContactType: (state, { payload }) => {
      state.contactType = payload;
    },
    setStatusLead: (state, { payload }) => {
      state.statusLead = payload;
    },
    resetContactForm: () => initialState,
  },
});

export const {
  openContact,
  closeContact,
  setSchoolId,
  onContact,
  addStudent,
  removeStudent,
  handleStudent,
  handleContact,
  setZip,
  setSubmitting,
  activateErrors,
  handleInput,
  setStage,
  setContactType,
  setStatusLead,
  resetContactForm,
} = contactSchoolSlice.actions;

export default contactSchoolSlice.reducer;

// Selectors to validate forms:
export const getMemoValidStudents = createSelector(
  (state) => state.contactSchool.students,
  (students) => {
    return students.every((item) => item.name && item.grade);
  }
);

export const validateContact = createSelector(
  (state) => state.contactSchool.contact,
  (state) => state.contactSchool.students,
  (contact, students) => {
    let errors = {};

    if (!contact.firstName) {
      errors.firstName = t('contactSchool:contact.errors.requiredFirstName');
    }
    if (!contact.lastName) {
      errors.lastName = t('contactSchool:contact.errors.requiredLastName');
    }
    if (!contact.email) {
      errors.email = t('contactSchool:contact.errors.requiredEmail');
    } else if (!isEmail(contact.email)) {
      errors.email = t('contactSchool:contact.errors.invalidEmail', { email: contact.email });
    }
    if (!contact.phone) {
      errors.phone = t('contactSchool:contact.errors.requiredPhone');
    } else if (!isPhone(contact.phone)) {
      errors.phone = t('contactSchool:contact.errors.invalidPhone', { phone: contact.phone });
    }

    if (!contact.zipcode) {
      errors.zipcode = t('contactSchool:contact.errors.requiredZipcode');
    } else if (!isZipCode(contact.zipcode)) {
      errors.zipcode = t('contactSchool:contact.errors.invalidZipcode', { zipcode: contact.zipcode });
    }

    if (!students.every((item) => item.name && item.grade)) {
      errors.students = 'Student should not be empty';
    }

    return errors;
  }
);

export const validateAdditionalMatch = createSelector(
  (state) => state.contactSchool.additionalMatch,
  (additionalMatch) => additionalMatch
);
