import { tString } from 'components/translation/Translation';
import i18next from 'translations/i18n';
import schoolUrl from 'util/schoolUrl';
import { getGradesRange } from '../school-profile/utils/get-grades-range';

export const getScholaMatchEmailContent = (schoolsChoice, scholamatchinfoStorageV5) => {
  if (schoolsChoice) {
    let schools = [];
    let count = 1;
    let language = scholamatchinfoStorageV5?.language ? scholamatchinfoStorageV5.language : i18next.language;
    i18next.changeLanguage(language);
    for (let idx in schoolsChoice) {
      const school = schoolsChoice[idx];
      if (school) {
        let _position = tString('scholaMatch.best_match');
        let color = count === 1 ? '#f0ad1b' : '#00ace5';
        switch (count) {
          case 2:
            _position = tString('scholaMatch.2nd_option');
            break;
          case 3:
            _position = tString('scholaMatch.3rd_option');
            break;
        }
        count++;
        let _payload = {
          id: school.id,
          name: school.name,
          url: schoolUrl.build(school),
          address: `${school.address.toLowerCase()}, ${school.city.toLowerCase()}, ${school.state.toLowerCase()}, ${
            school.zip
          }`,
          grades: getGradesRange(school.grades.join(',')),
          features: '',
          schoolType: school.type,
          color,
          position: _position,
        };
        schools.push(_payload);
      }
    }

    let childName = scholamatchinfoStorageV5?.studentName !== '' ? scholamatchinfoStorageV5?.studentName : 'your child';

    let parentName = scholamatchinfoStorageV5?.parentName || (language == 'en' ? 'parent' : 'padre/madre');

    let emailpayload = {
      parent_name: parentName,
      child_name: childName,
      email: scholamatchinfoStorageV5?.email,
      schools,
      language,
    };
    if (schools.length > 0) return emailpayload;
  }
};
