import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputText } from 'schola-components';
import { activateErrors, handleContact } from 'redux/slices/contact-school.slice';
import { t } from 'components/translation/Translation';
import { LanguageSelector, StudentsStage } from '..';
import { isZipcodeIncluded } from '../../utils';
import { CONTACT_TYPE } from 'redux/slices/types';

export const ContactStage = ({ errors }) => {
  const { contact, contactType, errorsActive } = useSelector((state) => state.contactSchool);
  const dispatch = useDispatch();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    dispatch(handleContact({ name, value }));
  };

  const handleInputBlur = (field) => {
    // Active error onBlur event, set for special formats
    dispatch(activateErrors({ field }));
  };

  const zipNeeded = isZipcodeIncluded(contactType);

  const zipCopys = {
    publicDistrict: t('contactSchool:contact.zipcodePublicDistrict'),
    recruiterPro: t('contactSchool:contact.zipcodeRecruiter'),
    multiple: t('contactSchool:contact.zipcodeMultiple'),
  };

  const inputs = [
    {
      id: 'firstName',
      label: t('contactSchool:contact.firstName'),
      name: 'firstName',
      value: contact.firstName,
      errorMessage: errorsActive.firstName && errors?.firstName,
    },
    {
      id: 'lastName',
      label: t('contactSchool:contact.lastName'),
      name: 'lastName',
      value: contact.lastName,
      errorMessage: errorsActive.lastName && errors?.lastName,
    },
    {
      id: 'phone',
      label: t('contactSchool:contact.phone'),
      type: 'tel',
      name: 'phone',
      value: contact.phone,
      errorMessage: errorsActive.phone && errors?.phone,
      onBlur: () => handleInputBlur('phone'),
    },
    {
      id: 'email',
      label: t('contactSchool:contact.email'),
      type: 'email',
      name: 'email',
      value: contact.email,
      errorMessage: errorsActive.email && errors?.email,
      onBlur: () => handleInputBlur('email'),
    },
  ];

  return (
    <div>
      {/* {contactType === CONTACT_TYPE.unclaimed && (
        <p className="message-unclaimed body-small spacing-mb-32">{t('contactSchool:contact.unclaimedLabel')}</p>
      )} */}

      <LanguageSelector />

      {inputs.map((input) => (
        <div key={input.id} className="spacing-mb-8">
          <InputText
            key={input.id}
            label={input.label}
            type={input.type ?? 'text'}
            id={input.id}
            name={input.name}
            value={input.value}
            onChange={handleChange}
            onBlur={input.onBlur}
            error={input?.errorMessage}
            errorMessage={input?.errorMessage}
            className="spacing-mb-8"
          />
        </div>
      ))}
      <p className="body-small spacing-mb-8">{t('contactSchool:contact.zipcodeRecruiter')}</p>
      <InputText
        label={t('contactSchool:contact.zipcode')}
        id="zipcode"
        name="zipcode"
        value={contact.zipcode}
        onChange={handleChange}
        error={errorsActive.zipcode && errors?.zipcode}
        errorMessage={errorsActive.zipcode && errors?.zipcode}
        onBlur={() => handleInputBlur('zipcode')}
      />

      {/* Student input on the same stage to save steps: */}
      <h4 className="spacing-my-32">Tell us more about the student(s)</h4>
      <StudentsStage isError={errorsActive.students && errors?.students} />
    </div>
  );
};
